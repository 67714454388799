import { reactive, ref } from "vue";
import { Form } from "ant-design-vue";
import useValidateRules from "@/hooks/useValidateRules";
import { useStore } from "vuex";
import router from "@/router";
import useMessage from "@/hooks/useMessage";

const useForm = Form.useForm;
const useLogin = () => {
  const form = reactive({
    account: "",
    password: "",
  });
  const store = useStore();
  const { loginRules } = useValidateRules();
  const { showErr } = useMessage();
  const { validateInfos, validate } = useForm(form, loginRules);
  const disabled = ref(false);
  const doSubmit = () => {
    validate().then(async () => {
      try {
        disabled.value = true;
        await store.dispatch("user/doLogin", {
          account: form.account,
          password: form.password,
        });
        await router.push({
          path: "/dashboard",
          redirect: true,
        });
      } catch (e) {
        await showErr(e);
        disabled.value = false;
      }
    });
  };
  return {
    form,
    validateInfos,
    disabled,
    doSubmit,
  };
};
export default useLogin;
