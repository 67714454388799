<template>
  <div class="login-container">
    <div class="login-card">
      <a-row style="height: 100%" align="middle">
        <a-col :span="11" :offset="12">
          <div class="title">欢迎登陆</div>
          <a-form>
            <a-form-item v-bind="validateInfos.account">
              <a-input
                v-model:value="form.account"
                placeholder="请输入账号"
                :maxlength="16"
              >
                <template #prefix>
                  <UserOutlined />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item v-bind="validateInfos.password">
              <a-input-password
                v-model:value="form.password"
                placeholder="请输入密码"
                :maxlength="16"
              >
                <template #prefix>
                  <UnlockOutlined />
                </template>
              </a-input-password>
            </a-form-item>
            <a-form-item>
              <a-button
                :disabled="disabled"
                type="primary"
                block
                @click="doSubmit"
                >登录</a-button
              >
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import useLogin from "@/hooks/useLogin";
export default {
  name: "index",
  components: {},
  setup() {
    const { form, validateInfos, doSubmit, disabled } = useLogin();
    return {
      form,
      validateInfos,
      disabled,
      doSubmit,
    };
  },
};
</script>

<style scoped>
.login-container {
  position: relative;
  background-image: url("../../assets/login_bg.png");
  text-align: center;
  background-size: 100% 100%;
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: local;
}

.login-card {
  position: absolute;
  width: 48%;
  height: 52%;
  left: 27%;
  top: 30%;
  /*background: rgba(255, 0, 0, 0.3);*/
}

.title {
  font-weight: 600;
  font-size: 22px;
  color: #333;
  margin-bottom: 30px;
}
</style>
